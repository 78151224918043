<template>
  <section
    class="template-dashboard-view edit-new-template-view"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
  >
    <div
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      v-loading.fullscreen.lock="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :fullscreen="true"
    >
      <div class="templates-title-block">
        <el-row style="margin: 10px 0 30px 0">
          <el-col :span="24">
            <el-row>
              <el-col :span="2">
                <el-button size="mini" @click="handleBack">
                  <i class="el-icon-back"></i> back</el-button
                >
              </el-col>
              <el-col :span="8">
                <h2
                  class="title fw-normal text-dark mb-0 ls-05"
                  style="margin-top: 5px"
                >
                  Email Templates
                  <span v-if="is_template_global">(Global)</span>
                  <span v-else>(Header & Footer)</span>
                </h2>
              </el-col>
              <el-col :span="2" style="float: right">
                <el-button
                  type="primary"
                  :disabled="checkIsDisable"
                  @click="createNewTemplate"
                >
                  {{ isEdit ? "Update" : "Save" }}
                </el-button>
              </el-col>
            </el-row>
            <el-card shadow="never" style="margin-top: 25px">
              <el-row>
                <el-col :span="14.5" style="padding: 3px">
                  <div style="margin-top: 5px">
                    <h5 style="font-weight: 400">Template Name</h5>
                    <el-input
                      v-model="form.name"
                      style="width: 400px"
                      placeholder="Enter Template Name"
                    >
                    </el-input>
                    <!-- <div> -->
                    <el-switch
                      v-model="is_template_global"
                      active-text="Global template"
                      inactive-text="Header footer template"
                      style="float: right; margin-left: 5px"
                      v-if="!checkIsEdit"
                      @change="handleTypeChange"
                      class="toggle-switch"
                    >
                    </el-switch>
                    <!-- </div> -->
                  </div>
                  <div style="margin-top: 5px">
                    <h5 style="font-weight: 400">Description</h5>
                    <el-input
                      v-model="form.description"
                      style="width: 400px"
                      placeholder="Enter Description"
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                    >
                    </el-input>
                  </div>
                  <div v-if="is_template_global">
                    <div style="margin-top: 5px">
                      <h5 style="font-weight: 400">Default Email Subject</h5>
                      <el-input
                        v-model="form.subject"
                        type="textarea"
                        style="width: 400px"
                        maxlength="998"
                        rows="1"
                        show-word-limit
                        placeholder="Enter Subject"
                      >
                      </el-input>
                    </div>
                    <div style="margin-top: 10px">Content (body) :</div>
                    <div style="margin-top: 10px">
                      <ckeditor
                        v-if="isEnable"
                        v-model="form.content"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                  <div v-else style="margin-top: 5px">
                    <div
                      style="display: flex; flex-wrap: wrap; margin-top: 5px"
                    >
                      <div style="margin: 0 10px 5px 0">
                        <h5 style="font-weight: 400">Header :</h5>
                        <ckeditor
                          v-model="form.header_content"
                          :config="editorConfig1"
                        ></ckeditor>
                      </div>
                      <div>
                        <h5 style="font-weight: 400">Footer :</h5>
                        <ckeditor
                          v-model="form.footer_content"
                          :config="editorConfig2"
                        ></ckeditor>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8"> </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
import EntityHelper from "@/mixins/EntitiesHelper";

import NavigationHelper from "@/mixins/navigationHelper";

export default {
  name: "formTemplates-New-HeaderFooterTemplate",
  data() {
    return {
      loadingText: "",
      loading: false,
      form: {
        name: "",
        description: "",
        subject: "",
        content: "",
      },
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorConfig1: {},
      editorConfig2: {},
      allMentionableFields: [],
      currentTemplateData: {},
      isEdit: false,
      userTypesEmails: null,
      is_template_global: true,
      userTypes: null,
      selectedUser: "",
      companyUsersData: "",
      allCompanyFields: null,
      allUserFields: null,
      allCustomFields: [
        {
          id: "current_date",
          label: "Time - Current Date",
        },
        {
          id: "current_time",
          label: "Time - Current Time",
        },
        {
          id: "current_date_time",
          label: "Time - Current Date Time",
        },
      ],
      isEnable : false,
    };
  },
  async mounted() {
    if (this.$route.path.includes("edit")) {
      this.isEdit = true;
      await this.fetchCurrentTemplateData(this.$route.params.template_id);
    }
    await this.getAllNecessaryDetails();
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityEmailTemplate", [
      "getAllCompanyEmailTemplatesData",
      "getDeleteEmailTemplateStatus",
      "getEmailTemplateError",
      "getCreatedEmailTemplateStatus",
      "getCurrentEmailTemplateData",
      "getUpdatedEmailTemplateStatus",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getCompanyUsers",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
    ]),
    ...mapGetters("company", [
      "getCompanyDetails",
      "getUpdateCompany",
      "getCompanyLogo",
    ]),
    checkIsDisable() {
      if (this.is_template_global) {
        if (
          !this.form.name ||
          !this.form.description ||
          !this.form.content ||
          !this.form.subject
        ) {
          return true;
        }
        return false;
      } else {
        if (
          !this.form.name ||
          !this.form.description ||
          !this.form.header_content ||
          !this.form.footer_content
        ) {
          return true;
        }
        return false;
      }
    },
    checkIsEdit() {
      return this.isEdit;
    },
  },
  mixins: [EntityHelper, NavigationHelper],
  methods: {
    async getAllNecessaryDetails() {
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><b>(({id}))</b><span>&nbsp;</span>",
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
          marker: "#",
        },
        // {
        //   feed: "/tags?name={encodedQuery}",
        //   marker: "#",
        // },
      ];
      this.editorConfig1 = {
        ...this.editorConfig,
        id : "header"
      }
      this.editorConfig2 = {
        ...this.editorConfig,
        id : "footer"
      }
      this.getCompanyInfo(this.isEdit);
      this.isEnable = true;
    },
    async getCompanyInfo(mountedCall = false) {
      this.loadingText = "Fetching company Fields";
      this.loading = true;
      const fieldMappings = {
        about_company_text: "Company - Company Description",
        email_domain: "Company - Email Domain",
        email_footer_text: "Company - Email Footer",
        legal_name: "Company - Legal Name",
        name: "Company -  Name",
        logo: "Company -  Logo",
      };
      this.allCompanyFields = Object.entries(fieldMappings).map(
        ([field, label]) => {
          return {
            label,
            value: field,
            id: field,
          };
        }
      );
      this.allCompanyFields = [
        ...this.allCustomFields,
        ...this.allCompanyFields,
      ];
      const userFieldMappings = {
        first_name: "Login User - First Name",
        last_name: "Login User -Last Name",
        email: "Login User -Email",
        phone: "Login User -Phone",
        owner_first_name: "Owner - First Name",
        owner_last_name: "Owner - Last Name",
        owner_email: "Owner - Email",
        owner_phone: "Owner - Phone",
      };
      let userFields = Object.entries(userFieldMappings).map(([field, label]) => {
        return {
          label,
          value: field,
          id: field,
        };
      });
      this.allUserFields = userFields;
      this.allMentionableFields = [...this.allCompanyFields, ...userFields];
      this.loading = false;

      if (!mountedCall) {
        this.resetForm(true);
      }
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return item[matchProperty].toLowerCase().indexOf(opts.query.toLowerCase()) !== -1;
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    resetForm() {
      if (this.is_template_global) {
        this.form = {
          name: "",
          description: "",
          content: "",
          subject: "",
        };
      } else {
        this.form = {
          name: "",
          description: "",
          header_content: "",
          footer_content: "",
        };
      }
    },
    async createNewTemplate() {
      if (!this.form.name.trim() || !this.form.description.trim()) {
        return this.$message.warning(
          "Only spaces are not allowed.Please enter text"
        );
      }
      if (this.is_template_global && !this.form.subject.trim()) {
        return this.$message.warning(
          "Only spaces are not allowed in subject.Please enter text"
        );
      }
      if (
        this.is_template_global &&
        !(this.form.content && this.form.content.length > 20)
      ) {
        return this.$message.warning(
          "Email body must be greater than 20 characters"
        );
      } else if (
        !this.is_template_global &&
        (!(this.form.header_content && this.form.header_content.length > 20) ||
          !(this.form.footer_content && this.form.footer_content.length > 20))
      ) {
        return this.$message.warning(
          "Email body must be greater than 20 characters"
        );
      }
      this.loadingText = "Creating Your Template";
      this.loading = true;
      if (!this.isEdit) {
        let params = {
          ...this.form,
        };
        params["type"] = this.is_template_global ? "GLOBAL" : "PARENT";
        await this.$store.dispatch(
          "entityEmailTemplate/createEmailTemplate",
          params
        );
        if (this.getCreatedEmailTemplateStatus) {
          this.loading = false;
          this.$message.success("Template Created Successfully");
          this.$router.push({ path: "/email-template" });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: this.getEmailTemplateError || "Error creating template",
          });
        }
      } else {
        let params = {
          ...this.form,
          company_id: this.currentTemplateData.company_id,
          created_at: this.currentTemplateData.created_at,
          created_by: this.currentTemplateData.created_by,
          updated_at: this.currentTemplateData.updated_at,
          updated_by: this.currentTemplateData.updated_by,
          _id: this.currentTemplateData._id,
        };
        await this.$store.dispatch(
          "entityEmailTemplate/updateTemplateData",
          params
        );
        if (this.getUpdatedEmailTemplateStatus) {
          this.loading = false;
          this.$message.success("Template Updated Successfully");
          this.$router.push({ path: "/email-template" });
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: this.getEmailTemplateError || "Error Updating template",
          });
        }
      }
    },
    async handleBack() {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: "/email-template",
        query,
      });
    },
    async fetchCurrentTemplateData(template_id) {
      this.loadingText = "Fetching Current Template data";
      let params = {
        template_id: template_id,
      };
      this.loading = true;
      await this.$store.dispatch(
        "entityEmailTemplate/currentTemplateData",
        params
      );
      if (this.getCurrentEmailTemplateData) {
        this.currentTemplateData = this.getCurrentEmailTemplateData;
        this.is_template_global =
          this.currentTemplateData.type == "GLOBAL" ? true : false;
        if (this.is_template_global) {
          this.form = {
            name: this.currentTemplateData.name,
            description: this.currentTemplateData.description,
            content: this.currentTemplateData.content,
            subject: this.currentTemplateData.subject,
            type : this.currentTemplateData.type
          };
        } else {
          this.form = {
            name: this.currentTemplateData.name,
            description: this.currentTemplateData.description,
            header_content: this.currentTemplateData.header_content,
            footer_content: this.currentTemplateData.footer_content,
            type : this.currentTemplateData.type
          };
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: this.getEmailTemplateError || "Error fetching template data",
        });
        this.$router.push("/email-template");
      }
    },
    // async getCompanyUserTypeList() {
    //   try {
    //     this.loadingText = "fetching company users";
    //     this.loading = true;
    //     await this.$store.dispatch("auth/getUserTypeList", {
    //       companyId: this.getActiveWorkspace.company_id,
    //       isMenu: true,
    //     });
    //     if (this.getUserTypeList) {
    //       this.userTypes = this.getUserTypeList;
    //       this.loading = false;
    //       await this.getUserTypeEmailsList();
    //     }
    //   } catch (err) {
    //     this.loading = false;
    //     this.$notify.error({
    //       title: "Error",
    //       message: "error fetching Company Users",
    //     });
    //     this.$router.push("/email-template");
    //   }
    // },
    // async getUserTypeEmailsList() {
    //   try {
    //     if (!this.userTypes.length) {
    //       this.userEmailsLoading = false;
    //       return;
    //     }
    //     let AllUserTypeIds = this.userTypes.map((e) => e._id);
    //     let params = {
    //       userTypes: AllUserTypeIds,
    //       includeData: true,
    //     };
    //     await this.$store.dispatch("auth/fetchUserTypeEmails", params);
    //     if (this.getUserTypeEmails) {
    //       this.userTypesEmails = this.getUserTypeEmails.data;
    //     }
    //   } catch (err) {
    //     this.loading = false;
    //     this.$notify.error({
    //       title: "Error",
    //       message: this.getUserTypeEmailsErrors,
    //     });
    //     this.$router.push("/email-template");
    //   }
    //   return;
    // },
    handleTypeChange() {
      if (this.is_template_global) {
        this.form = {
          name: "",
          description: "",
          content: "",
        };
      } else {
        this.form = {
          name: "",
          description: "",
          header_content: "",
          footer_content: "",
        };
      }
    },
    getLabelByUserTypeId(userTypeId) {
      let userTypeData = this.userTypes.filter((e) => e._id == userTypeId);
      if (userTypeData[0] && userTypeData[0].role) {
        return userTypeData[0].role.title + " - " + userTypeData[0].name;
      } else {
        return userTypeData[0].name;
      }
    },
  },
};
</script>
<!-- <style scoped>
.toggle-switch{

}
.is-active{
    background-color: none;
}
</style> -->